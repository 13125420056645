import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  useHistory,
  useRoutes,
} from "react-router-dom";
import { getCurrentUser, getData, putData, putUploadData } from "../../../api";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { FiDownload, FiHeart } from "react-icons/fi";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdHeart,
  IoMdShare,
} from "react-icons/io";
import DetailSection from "../../../components/Project/DetailSection";
import DetailSection2 from "../../../components/Project/DetailSection2";
import ContentCard from "../../../components/Project/ContentCard";
import BrochureAdmin from "../../../components/Project/BrochureAdmin";
import BrochureProject from "../../../components/Project/BrochureProject";
import FeatureDetailCard from "../../../components/Project/FeatureDetailCard";
import Map from "../../../components/Project/Map";
import { MdOutlineStreetview } from "react-icons/md";
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { FaChevronLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
// import React, { useEffect, useState, useRef } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
// SwiperCore.use([Navigation, Pagination, Autoplay, Scrollbar]);
import FormModal from "../../../components/Modal";
import ViewOnGoogleMap from "../../../components/googleMapButtons/ViewOnGoogleMap";
import ViewInStreetView from "../../../components/googleMapButtons/ViewInStreetView";
import MapWrapper from "../../../components/Maps/map";
import PropertyAmenties from "../../../components/propertyViewList/propertyAmenties";
// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "slick-carousel/slick/slick.css";
// import "./propertyView.scss";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NoIMage from "../../../assets/images/noImage.jpg";
import BusinessUse from "../../../components/Project/BusineesUse";
import moment from "moment";
// const MapComponent = dynamic(() => import("@/components/project/Map"), {
//   ssr: false,
// });
const ViewProject = () => {
  const keys = [
    "frontSide",
    "interior",
    "exterior",
    "buildingImages",
    "workSpaceImages",
    "commonAreaImages",
    "videoUrl",
  ];
  const router = useParams();
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  const [openModal, setOpenModal] = useState(false);
  const [ImageModal, setImageModal] = useState(false);
  const [currentSlideslide, setCurrentSlideslide] = useState(0);
  const [handleModal, setHandleModal] = useState(false);
  const [alreadyAdded, setReadyAdded] = useState(false);
  const [demandPropertiesData, setdemandPropertiesData] = useState({});
  const [projectDetailData, setProjectDetailData] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState({
    name: "Overview",
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [savedData, setSavedData] = useState();
  const [SimilarData, setSimilarData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const GetPorjectData = async (id) => {
    try {
      const url = `/project/getProjectById/${id}`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        setProjectDetailData(data?.getProject);
        if (data?.getProject?.imagesAndType) {
          const newImages = data.getProject.imagesAndType.filter(
            (image) => !allImages.some((img) => img.viewUrl === image.viewUrl)
          );
          if (newImages.length > 0) {
            console.log("New Images to Add:", newImages);
            setAllImages((prevImages) => {
              const updatedImages = [...newImages];
              console.log("Updated allImages:", updatedImages);
              return updatedImages;
            });
          }
        }
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  const PreviousBtn = (props) => {
    setCurrentSlide(props.currentSlide + 1);

    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IoIosArrowBack style={{ color: "white" }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    setCurrentSlide(props.currentSlide + 1);
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IoIosArrowForward style={{ color: "white" }} />
      </div>
    );
  };

  const getSimilarProjectData = async (id) => {
    try {
      const url = `/project/getSimilarProject//${id}`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        setSimilarData(data);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  const GetSaveData = async () => {
    try {
      setLoading(true);
      const data = `/project/getSaveProjects`;
      const result = await getData(data, {}, {});
      console.log("API result:", result); // Log the entire API result

      if (result?.status) {
        const projectList = result?.data?.projectList;
        if (projectList && projectList.length > 0) {
          const isSavedProject = projectList[0]?.isSavedProject;
          console.log("isSaveProject:", isSavedProject); // Log the specific value
          setReadyAdded(isSavedProject ? isSavedProject : false);
        } else {
          console.log("No projects found in the list");
          setReadyAdded(null);
        }
      } else {
        console.log("API call failed with status:", result?.status);
        setReadyAdded(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setReadyAdded(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (alreadyAdded) {

    GetSaveData();
    // }
  }, []);

  // save put
  const SaveDataHeart = async (id, data) => {
    console.log(data, "payload");
    setLoading(true);
    const response = await putData(
      `/project/saveUnsaveProject/${id}`,
      getCurrentUser()?.token,
      data
    );
    const { status, message } = response;
    if (status === true) {
      GetSaveData();
      setLoading(false);
      setHandleModal(false);
    } else {
      setLoading(false);
      setHandleModal(false);
    }
    // setLoading(false);
    // GetSaveData()
  };
  const formatPossessionDate = (dateStr) => {
    if (!dateStr) return "-"; // Handle cases where the date might be missing or invalid

    const formattedDate = moment(dateStr).format("MMMM D");
    return `${formattedDate}`;
  };

  const originalDate = new Date(projectDetailData?.possesionDate);
  originalDate.setDate(5);
  const year = originalDate.getUTCFullYear();
  const month = String(originalDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(originalDate.getUTCDate()).padStart(2, "0");
  const newDateStr = `${year}-${month}-${day}`;
  // Define the age range mapping
  const ProjectAge = {
    3: "Less than 5 years",
    2: "5 - 10 years",
    1: "Above 10 years",
  };

  // Determine the name based on conditions
  const ageOfProjectName =
    newDateStr === "NaN-NaN-NaN"
      ? ProjectAge[projectDetailData?.ageOfProject] || "-" // Use default value '-' if no match found
      : "-";

  const demoData2 = [
    {
      id: 1,
      title: "Total Carpet area in sqft.",
      name: projectDetailData?.carpetArea || "-",
    },
    {
      id: 2,
      title: "Project Status",
      name: projectDetailData?.projectStatus || "-",
    },
    {
      id: 3,
      title: "Possession Date",
      name: formatPossessionDate(projectDetailData?.possesionDate),
      hideContainer: projectDetailData?.projectStatus === "Ready to Move",
    },
    {
      id: 4,
      title: "Age of Project",
      name: ageOfProjectName,
      hideContainer: projectDetailData?.projectStatus === "Under Construction",
    },
  ];

  const demoData = [
    {
      id: 1,
      title: "Project Type",
      name: projectDetailData?.projTypes?.name || "-",
    },
    {
      id: 2,
      title: "Unit Sizes",
      name: `${projectDetailData?.unitSizeMin || 0} - ${
        projectDetailData?.unitSizeMax || 0
      } sq ft.`,
    },
    {
      id: 3,
      title: "Total built up area in sqft. ",
      name: projectDetailData?.builtupArea || "-",
    },
    {
      id: 4,
      title: "Property Type",
      name:
        projectDetailData?.propertyTypes?.map((v) => v.name).join(", ") || "",
    },
  ];

  const basicPropertyDetails = [
    {
      title: "Property Id",
      value: demandPropertiesData?.propertyTypeId?._id,
    },
    {
      title: "Transaction Type",
      value: demandPropertiesData?.transactionType,
    },
    { title: "Operator", value: demandPropertiesData?.operator },
  ];

  const demoProjectDetail = [
    {
      id: 1,
      title: "No of Parkings Available",
      value: ` ${projectDetailData?.parking || "-"} `,
    },
    {
      id: 2,
      title: "Transaction Type",
      value:
        projectDetailData?.transactionType?.map((v) => v).join(", ") || "-",
    },
    {
      id: 3,
      title: "Number of Buildings/Towers",
      value: projectDetailData?.noOfBuildingOrTower || "-",
    },
    {
      id: 4,
      title: "Rera ID ",
      value: projectDetailData?.reraId || "-",
    },
    {
      id: 5,
      title: "Rate Per Sqft on Carpet Area",
      value: `₹ ${projectDetailData?.priceRangeMin || "-"} - ${
        projectDetailData?.priceRangeMax || "-"
      }  `,
    },
    {
      id: 6,
      title: "OC Status",
      value: projectDetailData?.ocStatus ? "Yes" : "No",
    },
    {
      id: 7,
      title: "CAM Charges",
      value: ` ₹ ${projectDetailData?.camCharges || 0}`,
    },
    {
      id: 8,
      title: "Units Available",
      value: `${projectDetailData?.unitAvailable || 0} Units`,
    },
  ];

  const demoAmenities = projectDetailData?.otherAmenities?.map((value) => {
    return {
      name: value?.name,
      id: value?._id,
    };
  });

  console.log({ demoAmenities });

  const DemoSwiperimages = projectDetailData?.otherDocuments?.brochure?.map(
    (v) => {
      return v;
    }
  );

  const DemoSwiperimages2 = projectDetailData?.otherDocuments?.floorPlans?.map(
    (v) => {
      return v?.viewUrl;
    }
  );

  // useEffect(() => {
  //   DemandDetailsViewFun();

  // }, []);

  useEffect(() => {
    if (router?.slug) getSimilarProjectData(router?.slug);
    if (router?.slug) {
      GetPorjectData(router?.slug);
    }
  }, [router?.slug]);

  // let mydata = SimilarData?.getData?.map((v) => {
  //   return {
  //     name: v?.projectName,
  //     location: v?.location,
  //     id: v?._id,
  //     Image: `/images/DemandCards.png`
  //   }
  // })

  // useEffect(() => {
  //   if (
  //     alreadyAdded
  //   ) {
  //     SaveDataHeart(router?.slug, {
  //       "isSaveProject": alreadyAdded
  //     })
  //   }
  // }, [router?.slug, alreadyAdded])

  // Default image for errors
  const defaultImage = "/images/galleryImage.png";

  // Function to format the Swiper navigation buttons
  const swiperNavigationStyles = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    height: "80px",
    width: "40px",
    zIndex: 20,
    cursor: "pointer",
    fontSize: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #08536B",
    background: "rgba(0, 37, 31, 0.2)",
    backdropFilter: "blur(10px)",
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);

  return (
    <div className="p-2  rounded-2" style={{ background: "white" }}>
      <div className="d-flex gap-2 px-4 py-3 align-items-center self-items-center">
        <FaChevronLeft
          onClick={() => {
            handleNavigation(-1);
          }}
          style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
        />
        <h5 className="pt-2">View Project</h5>
      </div>
      {/* image container */}

      <div className="px-5">
        {/* Image Grid */}
        <div
          className="d-none d-sm-grid"
          style={{
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "0.5rem",
            position: "relative",
            height: "70%",
          }}
        >
          <div className="position-relative border" style={{ height: "600px" }}>
            <img
              src={
                projectDetailData?.imagesAndType?.[0]?.viewUrl || defaultImage
              }
              alt="Project Image"
              className="custom-transform cursor-pointer"
              style={{
                width: "100%",
                height: "600px",
                filter: "brightness(90%)",
              }}
              onClick={() => {
                setCurrentImageIndex(0);
                setCurrentImageUrl(
                  projectDetailData?.imagesAndType?.[0]?.viewUrl || defaultImage
                );
                setImageModal(true);
              }}
              onError={(event) => {
                event.target.src = defaultImage;
              }}
            />
            <div
              className="text-dark bg-light rounded-pill px-2 py-1 position-absolute"
              style={{ top: "1rem", right: "1rem" }}
            >
              Posted by:{" "}
              {projectDetailData?.developerDetails?.displayName || "Unknown"}
            </div>
          </div>
          <div
            className="d-grid"
            style={{
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "0.5rem",
              height:
                projectDetailData?.imagesAndType?.length > 2
                  ? "300px"
                  : "600px",
            }}
          >
            {projectDetailData?.imagesAndType
              ?.slice(1, 5)
              .map((image, index) => (
                <div className="w-100 h-100" key={index}>
                  {["png", "jpg", "jpeg", "webp"].includes(image?.type) ? (
                    <img
                      src={image?.viewUrl || defaultImage}
                      alt="Project Image"
                      className="custom-transform cursor-pointer"
                      style={{
                        width: "100%",
                        height: "294px",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        setCurrentImageIndex(index + 1); // Adjust index based on position in the array
                        setCurrentImageUrl(image?.viewUrl || defaultImage);
                        setImageModal(true);
                      }}
                      onError={(event) => {
                        event.target.src = defaultImage;
                      }}
                    />
                  ) : image?.type === "youtube" ? (
                    <iframe
                      src={image?.viewUrl?.replace("/watch?v=", "/embed/")}
                      style={{
                        width: "100%",
                        height:
                          projectDetailData?.imagesAndType?.length > 2
                            ? "245px"
                            : "300px",
                      }}
                    ></iframe>
                  ) : image?.type === "vimeo" ? (
                    <iframe
                      src={image?.viewUrl?.replace(
                        "vimeo.com/",
                        "player.vimeo.com/video/"
                      )}
                      style={{
                        width: "100%",
                        height:
                          projectDetailData?.imagesAndType?.length > 2
                            ? "245px"
                            : "300px",
                      }}
                    ></iframe>
                  ) : (
                    <video
                      autoPlay
                      muted
                      style={{
                        width: "100%",
                        height:
                          projectDetailData?.imagesAndType?.length > 2
                            ? "245px"
                            : "300px",
                      }}
                    >
                      <source
                        src={image?.viewUrl}
                        type={`video/${image?.type}`}
                      />
                    </video>
                  )}
                </div>
              ))}
          </div>
          {projectDetailData?.imagesAndType?.length > 5 && (
            <button
              className="btn btn-secondary position-absolute text-capitalize"
              style={{ bottom: "1rem", right: "1rem" }}
              onClick={() => setImageModal(true)} // Adjusted to always open the modal
            >
              View All
            </button>
          )}
        </div>

        {/* Modal View All */}
        <div className="bg-black">
          <FormModal
            show={ImageModal}
            onClose={() => setImageModal(false)}
            heading="Image Listing"
            size="xl"
          >
            <div
              style={{ width: "100%", minHeight: "70vh", position: "relative" }}
            >
              <Swiper
                slidesPerView={1}
                scrollbar={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                speed={500}
                loop
                pagination={{ clickable: true }}
                navigation={{
                  nextEl: ".bannerPrev",
                  prevEl: ".bannerNext",
                  disabledClass: "#08536B",
                }}
                modules={[Navigation]}
                initialSlide={currentImageIndex} // Initialize the swiper with the selected index
                onSlideChange={(slide) => setCurrentSlide(slide.realIndex)}
                className="mySwiper landing-swiper w-100 position-relative"
              >
                {projectDetailData?.imagesAndType?.map((slideMedia, idx) => {
                  const { type, viewUrl } = slideMedia;
                  return (
                    <SwiperSlide
                      key={idx}
                      className="position-relative"
                      style={{
                        width: "100%",
                        height: "400px",
                        filter: "brightness(90%)",
                      }}
                    >
                      {type === "png" ||
                      type === "jpg" ||
                      type === "jpeg" ||
                      type === "webp" ? (
                        <img
                          src={viewUrl || defaultImage}
                          alt="Project Image"
                          width={1000}
                          height={1000}
                          style={{
                            width: "100%",
                            height: "100%",
                            filter: "brightness(90%)",
                          }}
                          unoptimized
                          onError={(event) => {
                            event.target.src = defaultImage;
                          }}
                        />
                      ) : type === "youtube" ? (
                        <iframe
                          src={viewUrl?.replace("/watch?v=", "/embed/")}
                          style={{
                            width: "100%",
                            height: "400px",
                            filter: "brightness(50%)",
                          }}
                        ></iframe>
                      ) : (
                        <video autoPlay muted className="w-100 h-100">
                          <source src={viewUrl} type={`video/${type}`} />
                        </video>
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {/* Custom navigation buttons */}
              <div>
                <div
                  id="bannerNext"
                  className="bannerNext"
                  style={{ ...swiperNavigationStyles, left: "4px" }}
                >
                  <IoIosArrowBack style={{ color: "white" }} />
                </div>
                <div
                  id="bannerPrev"
                  className="bannerPrev"
                  style={{ ...swiperNavigationStyles, right: "4px" }}
                >
                  <IoIosArrowForward
                    style={{ color: "white", fontWeight: "bold" }}
                  />
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                }}
              >
                {currentSlide + 1}/{projectDetailData?.imagesAndType?.length}
              </div>
            </div>
          </FormModal>
        </div>
      </div>

      {/*  */}
      <div className="px-5 py-3">
        <Row className="d-flex flex-column gap-1 flex-md-row gap-5 justify-content-between align-items-center">
          <Col className="shadow-sm p-3  bg-white rounded-2">
            <h5
              className="text-dark "
              style={{ fontSize: "28px", fontWeight: "bold" }}
            >
              {projectDetailData?.projectName}
            </h5>
            <br />
            <div
              className="text-secondary d-flex gap-2 pb-1"
              style={{ fontSize: "base", fontWeight: "normal" }}
            >
              <span>By Developer : </span>
              <span className="font-weight-bold text-dark text-decoration-underline">
                {projectDetailData?.developerDetails?.name}
              </span>
            </div>
            <br />
            <div
              className="text-secondary pb-1"
              style={{ fontSize: "base", fontWeight: "normal" }}
            >
              <span>Project Id : </span>
              <span className="font-weight-bold text-dark text-decoration-underline">
                {projectDetailData?.productId?.display}
              </span>
            </div>
            <div className="d-flex align-items-center py-2">
              <IoLocationOutline />
              <span className="font-weight-bold text-dark ml-2 rounded-full px-1 py-1">
                {projectDetailData?.location +
                  "," +
                  projectDetailData?.pincode +
                  " " || "Andheri East, Mumbai, Maharashtra, 400067"}
              </span>
            </div>
          </Col>
          {/* <Col className="d-flex gap-3 mb-4 mt-3 mb-md-0 justify-content-between">
            <div className='d-flex gap-3 '>
              <div style={{ width: '7rem', maxWidth: '7rem', background: '#F4F6F5', cursor: 'pointer' }} className='d-flex flex-column p-2 rounded-2  justify-content-center align-items-center'>
                <FiDownload style={{ fontSize: '2rem', color: "#004A6E" }} />
                < span >
                  Download
                </span>
              </div>
              {!loading ? <div onClick={() => {
                setHandleModal(true)
                // setReadyAdded(!alreadyAdded)
              }} style={{ width: '7rem', maxWidth: '7rem', background: '#F4F6F5', cursor: 'pointer' }} className='d-flex flex-column p-2 rounded-2  justify-content-center align-items-center'>
                <IoMdHeart style={{ fontSize: '1.5rem', color: alreadyAdded ? "red" : "#004A6E" }} />
                < span>
                  Save
                </span>
              </div> : <Loader />}
              <div style={{ width: '7rem', maxWidth: '7rem', background: '#F4F6F5', cursor: 'pointer' }} className='d-flex flex-column p-2 rounded-2  justify-content-center align-items-center'>
                <IoMdShare style={{ fontSize: '2rem', color: "#004A6E" }} />
                < span>
                  Share
                </span>
              </div>
            </div>
          </Col> */}
        </Row>

        <div className="my-3">
          {<DetailSection demoData={demoData} />}
          {<DetailSection2 demoData2={demoData2} />}
        </div>

        <div className="pt-3 shadow p-3 my-4  bg-white rounded-2">
          <h2 className=" fs-5" style={{ color: "#0B262A" }}>
            About Project
          </h2>
          <p
            className="text-secondary fs-5 fw-light"
            style={{ color: "#406065" }}
          >
            {projectDetailData?.aboutProject}
          </p>
        </div>

        {/* <div className="div">
          <div className="row">
            {basicPropertyDetails.map((data, index) => {
              return (
                data?.value && (
                  <div className="col-md-4" key={index}>
                    <div className="border border-2 border-secondary p-3 mb-4">
                      <h5 className=" font-medium">{data.title}</h5>
                      <p className="">{data.value}</p>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div> */}

        <div className="my-1 shadow p-3 my-4  bg-white rounded-2">
          <div className="mb-3">
            <h4 className=" font-Poppins fs-5" style={{ color: "#0B262A" }}>
              Property Details
            </h4>
          </div>
          <div className=" px-4 ">
            <div className="row">
              {demoProjectDetail.map((data, index) => {
                return (
                  data?.value !== undefined && (
                    <div className="col-md-4 p-4 border-bottom" key={index}>
                      <div>
                        <h5
                          className=" text-sm mb-2 fs-6"
                          style={{ color: "#406065" }}
                        >
                          {data.title}
                        </h5>
                        <p
                          className=" text-base font-normal fs-5"
                          style={{ color: "#406065" }}
                        >
                          {data.value || "-"}
                        </p>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
            <div className="row">
              <div className="col-md-12 p-4">
                <div>
                  <h5 className=" text-sm" style={{ color: "#406065" }}>
                    Current Tenants
                  </h5>
                  <p
                    className=" text-base fs-5 font-normal"
                    style={{ color: "#406065" }}
                  >
                    {projectDetailData?.tenants || "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {projectDetailData?.endUse?.length > 0 && (
          <div className="md:my-2 shadow p-3 my-4 bg-white rounded-2">
            <label className="Property-Details-text my-2">Business Use</label>
            <BusinessUse
              apiData={projectDetailData}
              // data={data6}
              sm={12}
              md={1}
              pdf={false}
            />
          </div>
        )}

        {/* {<div className="my-3  border-2  border-secondary">
          {projectDetailData?.otherAmenities && <div>
            <h4 className=" font-Poppins">Amenities</h4>
          </div>}
          <div className="row my-4 p-4">
            {projectDetailData?.otherAmenities?.map((data, index) => {
             
              return <div  className='col-2 py-5' key={index}>
                <ContentCard
                key={index}
                title={data?.name}
                titleIcon={data?.image?.viewUrl}
                // needEmptyBoxRight={true}
                rightViewClassName=""
                titleClassName="text-[#406065] font-medium "
              />
              </div>
            })}
          </div>
        </div>} */}

        {projectDetailData?.otherAmenities && (
          <div className="bg-white Ameneties my-4  ">
            <label className="Property-Details-text my-2">Amenties</label>
            {projectDetailData?.otherAmenities && (
              <div>
                <PropertyAmenties
                  apiData={projectDetailData}
                  // data={data6}
                  sm={12}
                  md={1}
                  pdf={false}
                />
              </div>
            )}
          </div>
        )}

        {projectDetailData?.otherDocuments?.brochure?.length > 0 && (
          <div className="shadow p-3  my-5 bg-white rounded-2">
            {projectDetailData?.otherDocuments?.brochure?.length > 0 && (
              <div>
                <h1 className="fs-4">Property Documents</h1>
              </div>
            )}
            {projectDetailData?.otherDocuments?.brochure?.length > 0 && (
              <BrochureAdmin DemoSwiperimages={DemoSwiperimages} />
            )}
          </div>
        )}

        {projectDetailData?.otherDocuments?.floorPlans?.length > 0 && (
          <div className="md:my-2 shadow p-3 my-4 bg-white rounded-2">
            <BrochureProject
              DemoSwiperimages={projectDetailData?.otherDocuments?.floorPlans}
            />
          </div>
        )}

        {/*  */}

        {projectDetailData?.propertiesData?.length > 0 && (
          <div className="shadow p-3 my-5 bg-white rounded-2">
            <FeatureDetailCard data={projectDetailData?.propertiesData} />
          </div>
        )}

        {/* map */}
        {projectDetailData?.gMapLatLong?.length > 0 && (
          <div className="property-docs bg-white w-100  mb-5" id="mapDivision">
            <div className="">
              <div className="col col-lg-4 col-sm-12">
                <label className="body-text-heading my-2">Property Map</label>
              </div>
              {projectDetailData?.gMapLatLong &&
                projectDetailData?.gMapLatLong?.length > 0 && (
                  <div className="col col-lg-8 col-sm-12 d-flex align-items-center justify-content-end p-3 gap-3">
                    <ViewInStreetView
                      cordinates={projectDetailData?.gMapLatLong}
                    />
                    <ViewOnGoogleMap
                      cordinates={projectDetailData?.gMapLatLong}
                    />
                  </div>
                )}
            </div>

            <MapWrapper data={projectDetailData?.nearBylocations} />
          </div>
        )}

        {/*  */}

        {projectDetailData?.aboutDeveloper && (
          <div className="">
            <h2 className=" text-sm fs-4">About Developer</h2>

            <p className=" fs-6 font-normal" style={{ color: "#406065" }}>
              {projectDetailData?.aboutDeveloper}
            </p>
          </div>
        )}
        <Modal
          onClose={() => {
            setHandleModal(false);
          }}
          show={handleModal}
          heading={`${alreadyAdded ? "UnSave" : "Save"}`}
          size={"md"}
          loader="fixed"
        >
          <div class="d-flex justify-content-between">
            <button
              class="btn btn-secondary"
              onClick={() => {
                setHandleModal(false);
              }}
            >
              close
            </button>
            <button
              class="btn btn-primary"
              onClick={() => {
                SaveDataHeart(router?.slug, {
                  isSaveProject: !alreadyAdded,
                });
              }}
            >
              {alreadyAdded ? "UnSave" : "Save"}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ViewProject;
