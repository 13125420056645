import React, { useEffect, useState } from "react";
import { getData, postGetData } from "../../../api";
import { Row, Col, Form } from "react-bootstrap";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select";
import Button from "../../../components/Form/Button";
import { OfficeSpaceAdditonalnput } from "../options";
import MultiSelect from "../../../components/select/dropDown";
import { useLocation } from "react-router-dom";
const OfficeSpaceAdditionalDetails = ({
  gotoStep,
  propertyDetail,
  step,
  propertyDetailsInputValues,
  properytyObject,
  setProperyObject,
  officeSpaceAdditionalStepButtonRef,
  officeSpaceAdditionalStepFormRef,
  setChanged,
  initData,
  AdditionalDetailDivRef,
}) => {
  const [properytyData, setPropertyData] = useState([]);
  // const [properytyObject, setProperyObject] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datasAmenitey, setDatasAmenitey] = useState([]);
  const [idealData, setIdealData] = useState([]);
  const [idealLoading, setIdealLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const handleChange = (e, item) => {
    setChanged(true);
    const { name } = item;
    const value = e.target?.value;
    setProperyObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMultiChange = (selectedData, selectedname) => {
    setChanged(true);
    const optionData = { ...properytyObject };
    optionData[selectedname] = selectedData;
    setProperyObject(optionData);
  };
  const handleAmenitey = async () => {
    let url = "/amenity?type=Office space";
    const apiDatas = await getData(url, {}, {});
    const { statusCode, data } = apiDatas;
    if (statusCode === 200) {
      data?.forEach((val) => {
        val.label = val.name;
        val.value = val._id;
      });
      setDatasAmenitey(data);
    }
  };
  const getIdealForApiData = async () => {
    setIdealLoading(true);
    let url = "/endUse/getEndUses?type=Office space";

    const newData = await getData(url, {}, {});
    const { statusCode, data, status } = newData;
    if (status && statusCode === 200) {
      data.forEach((val) => {
        val.label = val.name;
        val.value = val._id;
      });
      setIdealData(data);
      setIdealLoading(false);
    }
    setIdealLoading(false);
  };
  useEffect(() => {
    setPropertyData(OfficeSpaceAdditonalnput);
    handleAmenitey();
    getIdealForApiData();
    OfficeSpaceAdditonalnput.forEach((item) => {
      setProperyObject((prev) => ({
        ...prev,
        [item.name]: "",
      }));
    });
    if (propertyDetail?.additionalDetails) {
      let exitingObj = {
        floor: propertyDetail?.additionalDetails.floor,
        totalFloors: propertyDetail?.additionalDetails.totalFloors,
        cabins: propertyDetail?.additionalDetails.cabins,
        meetingRooms: propertyDetail?.additionalDetails.meetingRooms,
        propertyAge: propertyDetail?.additionalDetails.propertyAge,
        bikeParking: propertyDetail?.additionalDetails.bikeParking,
        idealFor: propertyDetail?.additionalDetails.idealFor,
        carParking: propertyDetail?.additionalDetails.carParking,
        fitOuts: propertyDetail?.fitOuts,
        powerBackup: propertyDetail?.powerBackup === true ? "yes" : "no",
        otherAmenities: propertyDetail?.otherAmenities,
        washRooms: propertyDetail?.washRooms,
        mezzainineAvailable: propertyDetail?.mezzainineAvailable,
        toilet: propertyDetail?.toilet === true ? "yes" : "no",
        availablefloors: propertyDetail?.additionalDetails?.availablefloors,
      };
      setProperyObject(exitingObj);
    }
  }, []);

  // const properyDetailUpdate = async (data) => {
  //   const res = await postGetData("/property", {}, data);
  //   const { statusCode } = res;
  //   if (statusCode === 200) {
  //     setLoading(false);
  //     gotoStep(3, true);
  //   }
  // };

  const gotoBack = () => {
    gotoStep(step - 1, true);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    // if (form.checkValidity()) {
    //   let objData = {
    //     additionalDetails: {
    //       floor: properytyObject.floor,
    //       propertyAge: Number(properytyObject.propertyAge),
    //       totalFloors: Number(properytyObject.totalFloors),
    //       cabins: Number(properytyObject.cabins),
    //       meetingRooms: Number(properytyObject.meetingRooms),
    //       bikeParking: properytyObject.bikeParking,
    //       carParking: properytyObject.carParking,
    //       washRooms: Number(properytyObject.washRooms),
    //       idealFor: properytyObject.idealFor,
    //     },
    //     otherAmenities: properytyObject.otherAmenities,
    //     fitOuts: properytyObject.fitOuts,
    //     powerBackup: properytyObject.powerBackup === "yes" ? true : false,
    //     step: 2,
    //     propertyId: searchParams.get("propertyId"),
    //   };
    //   for (let propName in objData) {
    //     for (let innerobj in objData?.additionalDetails) {
    //       if (
    //         objData?.additionalDetails[innerobj] === "" ||
    //         objData.additionalDetails[innerobj]?.length === 0 ||
    //         !objData.additionalDetails[innerobj]
    //       ) {
    //         delete objData.additionalDetails[innerobj];
    //       }
    //     }
    //     if (
    //       !objData[propName] ||
    //       objData[propName] === "" ||
    //       objData[propName].length === 0
    //     ) {
    //       delete objData[propName];
    //     }
    //   }

    //   // properyDetailUpdate(objData);
    // }
  };
  return (
    <div className="property-details-container" ref={AdditionalDetailDivRef}>
      <Row>
        <Col md="1" />
        <Col md="10">
          <h4>Additional Details</h4>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            ref={officeSpaceAdditionalStepFormRef}
          >
            <Row>
              {propertyDetailsInputValues?.transactionType !== "Sales" ? (
                <Col md="6">
                  {!idealLoading ? (
                    <MultiSelect
                      onChange={(e) => handleMultiChange(e, "idealFor")}
                      isMulti={true}
                      options={idealData}
                      value={properytyObject["idealFor"]}
                      lable="Ideal For"
                      placeholder="Select Ideal For"
                    />
                  ) : null}
                </Col>
              ) : null}
              {properytyData &&
                properytyData.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.type === "text" || item.type === "number" ? (
                      <Col md="6">
                        <Input
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Col>
                    ) : null}
                    {item.type == "select" &&
                    item.name !== "powerBackup" &&
                    item.name !== "availablefloors" &&
                    item.name !== "mezzainineAvailable" ? (
                      <Col md="6">
                        <Select
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Col>
                    ) : null}
                    {item.type == "select" &&
                    (item.name === "powerBackup" ||
                      item.name === "availablefloors" ||
                      item.name === "mezzainineAvailable") &&
                    initData.developerType === "Developer" ? (
                      <Col md="6">
                        <Select
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Col>
                    ) : null}
                  </React.Fragment>
                ))}
              <Col md="6">
                <MultiSelect
                  onChange={(e) => handleMultiChange(e, "otherAmenities")}
                  isMulti={true}
                  options={datasAmenitey}
                  value={properytyObject["otherAmenities"]}
                  lable="Amenities available"
                  placeholder="Select Amenities"
                />
              </Col>

              <Col md="12" hidden={true}>
                <div className="text-end">
                  <Button
                    type="submit"
                    className="next-btn p-0"
                    loading={loading}
                    _ref={officeSpaceAdditionalStepButtonRef}
                  >
                    Next
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default OfficeSpaceAdditionalDetails;
