import { Row, Col } from "react-bootstrap";
import React from "react";
import "../propertyViewList/propertyDetail.css";

export default function PropertyViewList(props) {
  const { apiData } = props;
  const docCati = [
    "floorPlans",
    "fireNDC",
    "autocad",
    "licenses",
    "otherDocuments",
  ];

  let rentFP = [
    {
      value: "1-2 months",
      key: 2,
    },
    {
      value: "2-4 months",
      key: 4,
    },
    {
      value: "4-6 months",
      key: 6,
    },
    {
      value: "Over 6 months",
      key: 8,
    },
  ];
  let propertyAge = [
    {
      key: 5,
      value: "Less than 5 years",
    },
    {
      key: 10,
      value: "5-10 years",
    },
    {
      key: 20,
      value: "10-20 years",
    },
    {
      key: 30,
      value: "Above 20 years",
    },
  ];

  let possession = [
    {
      value: "Immediate",
      key: 1,
    },
    {
      value: "Within 3 months",
      key: 3,
    },
    {
      value: "Within 6 months",
      key: 6,
    },
    {
      value: "within 9 months",
      key: 9,
    },
    {
      value: "After 9 months",
      key: 12,
    },
  ];
  let totalFloors = [
    {
      value: "5",
      name: "Up to 5",
    },
    {
      value: "10",
      name: "Between 6 - 10",
    },
    {
      value: "15",
      name: "Between 11 - 15",
    },
    {
      value: "20",
      name: "Between 16 - 20",
    },
    {
      value: "25",
      name: "Between 21 - 25",
    },
    {
      value: "30",
      name: "Between 26 - 30",
    },
    {
      value: "31",
      name: "Beyond 31",
    },
  ];
  let leaseData = [
    {
      value: 1,
      name: "Every year",
    },
    { value: 3, name: "Every 3 years" },
    { value: 5, name: "Every 5 years" },
  ];
  let floorOption = [
    {
      _id: "0",
      name: "Basement",
    },
    {
      _id: "1",
      name: "Lower Ground",
    },
    {
      _id: "2",
      name: "Upper Ground",
    },
    {
      _id: "9",
      name: "Ground",
    },
    {
      _id: "3",
      name: "1st Floor",
    },
    {
      _id: "4",
      name: "2nd Floor",
    },
    {
      _id: "5",
      name: "3rd Floor",
    },
    {
      _id: "6",
      name: "4th Floor",
    },
    {
      _id: "7",
      name: "5th Floor",
    },
    {
      _id: "8",
      name: "Above 5",
    },
    {
      _id: "9",
      name: "Ground",
    },
    // {
    //   _id: "0",
    //   name: "Ground",
    // },
    // {
    //   _id: "1",
    //   name: "1",
    // },
    // {
    //   _id: "2",
    //   name: "2",
    // },
    // {
    //   _id: "3",
    //   name: "3",
    // },
    // {
    //   _id: "4",
    //   name: "4",
    // },
    // {
    //   _id: "5",
    //   name: "5",
    // },
  ];

  const checkForZero = (value) => {
    if (Number(value) === 100 || Number(value) === 110) {
      return 0;
    }
    return value;
  };

  const listingItems = (items) => {
    return (
      <>
        {items?.length > 0 ? (
          <ul className="m-0">
            {items.map((item) => {
              return (
                <li
                  key={item.name}
                  className="w-100 f-16 fw-500 demand-detail-lable-left word-wrap m-0"
                >
                  <span>
                    <span style={{ fontWeight: "550" }}>{item.name}</span>
                    <span>{` - ${item.stationName}`}</span>
                  </span>
                </li>
              );
            })}
          </ul>
        ) : (
          "-"
        )}
      </>
    );
  };

  return (
    <div>
      <Row>
        {props.typeData === "Primary Details" ? (
          <>
            {props.data
              ? props.data.map((item) => {
                  let feetValue = item.key === "ceilingHeight";
                  let lease =
                    item.key === "tenure" || item.key === "lockInPeriod";
                  let parking = item.key === "parking";
                  let propertyAvailable;
                  let ageOfProperty;
                  let floorVal;
                  let floorNumber;
                  let fitouts;
                  let businessHours;
                  let nearestConnectivity;
                  let toilet;
                  let ocStatus;
                  if (item?.key === "possession") {
                    if (item?.key in apiData) {
                      possession.filter((property) =>
                        apiData[item.key] === property.key
                          ? (propertyAvailable = property.value)
                          : "-"
                      );
                    }
                  }
                  if (item.key == "businessHours") {
                    businessHours = apiData[item.key]
                      ? `${apiData[item.key]} hours`
                      : "-";
                  }

                  if (item?.key === "propertyAge") {
                    if (
                      "additionalDetails" in apiData &&
                      item.key in apiData.additionalDetails
                    ) {
                      //dont change the "==" to "===" here!!!!!!!!
                      propertyAge.filter((age) =>
                        apiData.additionalDetails[item.key] == age.key
                          ? (ageOfProperty = age.value)
                          : "-"
                      );
                    }
                  }
                  if (item.key == "businessHours") {
                    businessHours = apiData?.additionalDetails[item.key]
                      ? `${apiData?.additionalDetails[item.key]} hours`
                      : "-";
                  }
                  if (item.key === "floor") {
                    floorOption.filter((floor) =>
                      apiData?.additionalDetails?.floor
                        ? apiData?.additionalDetails.floor == floor._id
                          ? (floorVal = floor.name)
                          : "-"
                        : ""
                    );
                  }
                  if (item.key === "availableFloor") {
                    floorOption.filter((floor) =>
                      apiData?.additionalDetails?.availableFloor
                        ? (floorNumber =
                            apiData?.additionalDetails?.availableFloor)
                        : "-"
                    );
                  }
                  if (item.key === "nearestConnectivity") {
                    nearestConnectivity =
                      apiData?.additionalDetails?.nearestConnectivity?.length >
                      0
                        ? apiData?.additionalDetails?.nearestConnectivity
                        : "-";
                  }
                  if (item.key === "fitOuts") {
                    fitouts = apiData[item.key] ? apiData[item.key] : "-";
                  }
                  // if (item.key === "toilet") {
                  //   toilet = apiData[item.key] ? apiData[item.key] : "-";
                  // }
                  // if (item.key === "ocStatus") {
                  //   ocStatus = apiData[item.key] ? apiData[item.key] : "-";
                  // }
                  if (
                    item.key === "powerBackup" &&
                    apiData.customerType !== "Developer" &&
                    apiData?.propertyTypeId?.name !== "Retail"
                  ) {
                    return null;
                  }
                  // if (item.key === "mezzainineAvailable" && apiData.customerType !== "Developer") {
                  //   return null
                  // }
                  if (
                    item.key === "toilet" &&
                    apiData.customerType !== "Developer" &&
                    apiData?.propertyTypeId?.name !== "Retail"
                  ) {
                    return null;
                  }

                  if (
                    (item.key == "tenure" || item.key == "lockInPeriod") &&
                    apiData.transactionType === "Sales"
                  )
                    return;

                  if (
                    item.key == "ownerShip" &&
                    apiData?.propertyTypeId?.name == "Retail" &&
                    apiData.transactionType === "Sales"
                  )
                    return;

                  return (
                    <>
                      {item.type === "all" ||
                      item.type === apiData?.propertyTypeId?.name ||
                      item.type.includes(apiData?.propertyTypeId?.name) ? (
                        <>
                          {apiData?.additionalDetails[item.key]?.length > 0 ||
                          apiData[item?.key]?.length > 0 ||
                          apiData[item?.key] > 0 ||
                          apiData[item?.key] === false ? (
                            <Col
                              sm={props.sm}
                              md={props.md}
                              className="column-data"
                            >
                              <div className="bg-light-card d-flex align-items-center Property-View-lable w-100">
                                <div className="w-50 Property-View-lable">
                                  {item.name}
                                </div>
                                <div
                                  className={
                                    "w-50 Property-View-lable-left word-wrap"
                                  }
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {item.key === "propertyTypeId"
                                    ? apiData[item.key]?.name
                                    : item.key === "microLocation"
                                    ? apiData[item.key]?.description
                                    : item.key in apiData
                                    ? item.key === "possession"
                                      ? propertyAvailable
                                        ? propertyAvailable
                                        : "-"
                                      : apiData[item.key] === true
                                      ? "Yes"
                                      : apiData[item.key] === false
                                      ? "No"
                                      : item.key === "fitOuts"
                                      ? fitouts
                                      : item.key === "businessHours"
                                      ? businessHours
                                      : apiData[item.key] +
                                        [
                                          feetValue
                                            ? " ft"
                                            : lease
                                            ? " years"
                                            : parking
                                            ? " units"
                                            : "",
                                        ]
                                    : item.key === "carParking"
                                    ? checkForZero(
                                        apiData.additionalDetails[item.key]
                                      )
                                    : item.key === "bikeParking"
                                    ? checkForZero(
                                        apiData.additionalDetails[item.key]
                                      )
                                    : item.key === "washRooms"
                                    ? checkForZero(apiData[item.key])
                                    : item.key === "propertyAge"
                                    ? ageOfProperty
                                      ? ageOfProperty
                                      : "-"
                                    : item.key === "floor"
                                    ? floorVal
                                      ? floorVal
                                      : "-"
                                    : item.key === "availableFloor"
                                    ? floorNumber
                                      ? floorNumber
                                      : "-"
                                    : item?.key === "businessHours"
                                    ? businessHours || "-"
                                    : item?.key === "nearestConnectivity"
                                    ? listingItems(nearestConnectivity) || "-"
                                    : "-"}
                                </div>
                              </div>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              : ""}
          </>
        ) : props.typeData === "Property Cost" ? (
          <>
            {apiData.transactionType !== "Sales" && (
              <Col sm={props.sm} md={props.md} className="mt-2">
                <div className="bg-light-card d-flex align-items-center Property-View-lable">
                  <div className="w-50 Property-View-lable">
                    {"Deposit in months"}
                  </div>
                  <div className={"w-50 Property-View-lable-left word-wrap"}>
                    {/* Individual */}
                    {apiData?.deposit
                      ? apiData?.deposit === 1
                        ? apiData?.deposit + " month"
                        : apiData?.deposit > 1
                        ? apiData?.deposit + " months"
                        : "-"
                      : " -"}
                  </div>
                </div>
              </Col>
            )}
            <Col sm={props.sm} md={props.md} className="mt-2">
              <div className="bg-light-card d-flex align-items-center Property-View-lable">
                <div className="w-50 Property-View-lable">
                  {"Rate per sqft on carpet area"}
                </div>
                <div className={"w-50 Property-View-lable-left word-wrap"}>
                  {/* Individual */}
                  {apiData?.rentPsf ? "Rs: " + apiData.rentPsf : "-"}
                </div>
              </div>
            </Col>
            {"negotiable" in apiData && (
              <Col sm={props.sm} md={props.md} className="mt-2">
                <div className="bg-light-card d-flex align-items-center Property-View-lable">
                  <div className="w-50 Property-View-lable">{"Negotiable"}</div>
                  <div className={"w-50 Property-View-lable-left word-wrap"}>
                    {"negotiable" in apiData
                      ? apiData.negotiable
                        ? "Yes"
                        : "No"
                      : "-"}
                  </div>
                </div>
              </Col>
            )}
            {"brokerage" in apiData && (
              <Col sm={props.sm} md={props.md} className="mt-2">
                <div className="bg-light-card d-flex align-items-center Property-View-lable">
                  <div className="w-50 Property-View-lable">
                    {"Brokerage in days"}
                  </div>
                  <div className={"w-50 Property-View-lable-left word-wrap"}>
                    {/* Individual */}
                    {"brokerage" in apiData
                      ? toString(apiData?.brokerage)
                        ? apiData?.brokerage === 35
                          ? "negotiable"
                          : apiData?.brokerage + " days"
                        : "-"
                      : "-"}
                  </div>
                </div>
              </Col>
            )}
          </>
        ) : props.typeData === "More Details" ? (
          <>
            {props.data
              ? props.data.map((item) => {
                  let rentFreePeriod;
                  let ageOfProperty;
                  let leaseEscalationValue;
                  let totalFloor;
                  let floorVal;
                  if (item?.key === "rentFreePeriod") {
                    if (item?.key in apiData) {
                      rentFP.filter((rent) =>
                        apiData[item.key] === rent.key
                          ? (rentFreePeriod = rent.value)
                          : "-"
                      );
                    }
                  }
                  if (item?.key === "propertyAge") {
                    if (apiData?.hasOwnProperty("additionalDetails")) {
                      if (item.key in apiData?.additionalDetails) {
                        //dont change the "==" to "===" here!!!!!!!!
                        propertyAge.filter((age) =>
                          apiData?.additionalDetails[item.key] == age?.key
                            ? (ageOfProperty = age.value)
                            : "-"
                        );
                      } else {
                        ageOfProperty = "-";
                      }
                    }
                  }
                  if (item.key === "leaseEscalationValue") {
                    leaseData.filter((lease) =>
                      apiData?.leaseEscalationValue
                        ? apiData?.leaseEscalationValue === lease.value
                          ? (leaseEscalationValue = lease.name)
                          : "-"
                        : ""
                    );
                  }
                  if (item.key === "totalFloors") {
                    // totalFloors.filter(floor => apiData?.additionalDetails?.totalFloors ? apiData?.additionalDetails.totalFloors == floor.value ? totalFloor = floor.name : '-' : '');
                    totalFloor = apiData.additionalDetails[item.key] || "-";
                  }
                  if (item.key === "floor") {
                    floorOption.filter((floor) =>
                      apiData?.additionalDetails?.floor
                        ? apiData?.additionalDetails.floor == floor._id
                          ? (floorVal = floor.name)
                          : "-"
                        : ""
                    );
                  }
                  let feet =
                    item.key === "frontAge" || item.key === "accessRoadWidth";
                  return (
                    <>
                      {(item.type === "all" ||
                        item.type === apiData?.propertyTypeId?.name ||
                        item.type.includes(apiData?.propertyTypeId?.name)) &&
                      item.key !== "lockInPeriod" &&
                      item.key !== "rentFreePeriod" ? (
                        <Col
                          sm={props.sm}
                          md={props.md}
                          className="mt-2"
                          id={item.key}
                        >
                          <div className="bg-light-card d-flex align-items-center Property-View-lable">
                            <div className="w-50 Property-View-lable">
                              {item.name}
                            </div>
                            <div
                              className={
                                "w-50 Property-View-lable-left word-wrap"
                              }
                            >
                              {item.key === "totalFloors"
                                ? totalFloor
                                  ? totalFloor
                                  : "-"
                                : item.key === "floor"
                                ? floorVal
                                  ? floorVal
                                  : "-"
                                : item.key === "leaseEscalationValue"
                                ? leaseEscalationValue
                                  ? leaseEscalationValue
                                  : "-"
                                : item.key === "rentFreePeriod"
                                ? rentFreePeriod
                                  ? rentFreePeriod
                                  : "-"
                                : item.key === "lockInPeriod"
                                ? item.key in apiData
                                  ? apiData[item.key] === 1
                                    ? apiData[item.key] + " year"
                                    : apiData[item.key] + " years"
                                  : "-"
                                : "additionalDetails" in apiData
                                ? item.key in apiData?.additionalDetails
                                  ? item.key === "propertyAge"
                                    ? ageOfProperty
                                      ? ageOfProperty
                                      : "-"
                                    : item.key === "cabins"
                                    ? checkForZero(
                                        apiData?.additionalDetails[item.key]
                                      )
                                    : item.key === "meetingRooms"
                                    ? checkForZero(
                                        apiData.additionalDetails[item.key]
                                      )
                                    : apiData.additionalDetails[item.key] +
                                      [feet ? " ft" : ""]
                                  : item.key
                                  ? apiData[item.key]
                                    ? apiData[item.key]
                                    : "-"
                                  : "-"
                                : "-"}
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      {item.key === "lockInPeriod" &&
                      (apiData.customerType === "Developer" ||
                        apiData?.propertyTypeId?.name !== "Retail") &&
                      apiData.transactionType !== "Sales" ? (
                        <React.Fragment>
                          <Col sm={props.sm} md={props.md} className="mt-2">
                            <div className="bg-light-card d-flex align-items-center Property-View-lable">
                              <div className="w-50 Property-View-lable">
                                {item.name}
                              </div>
                              <div
                                className={
                                  "w-50 Property-View-lable-left word-wrap"
                                }
                              >
                                {item.key === "totalFloors"
                                  ? totalFloor
                                    ? totalFloor
                                    : "-"
                                  : item.key === "leaseEscalationValue"
                                  ? leaseEscalationValue
                                    ? leaseEscalationValue
                                    : "-"
                                  : item.key === "rentFreePeriod"
                                  ? rentFreePeriod
                                    ? rentFreePeriod
                                    : "-"
                                  : item.key === "lockInPeriod"
                                  ? item.key in apiData
                                    ? apiData[item.key] === 1
                                      ? apiData[item.key] + " year"
                                      : apiData[item.key] + " years"
                                    : "-"
                                  : "additionalDetails" in apiData
                                  ? item.key in apiData?.additionalDetails
                                    ? item.key === "propertyAge"
                                      ? ageOfProperty
                                        ? ageOfProperty
                                        : "-"
                                      : item.key === "cabins"
                                      ? checkForZero(
                                          apiData?.additionalDetails[item.key]
                                        )
                                      : item.key === "meetingRooms"
                                      ? checkForZero(
                                          apiData.additionalDetails[item.key]
                                        )
                                      : apiData.additionalDetails[item.key] +
                                        [feet ? " ft" : ""]
                                    : item.key
                                    ? apiData[item.key]
                                    : "-"
                                  : "-"}
                              </div>
                            </div>
                          </Col>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {item.key === "rentFreePeriod" &&
                      apiData.customerType === "Developer" ? (
                        <React.Fragment>
                          <Col sm={props.sm} md={props.md} className="mt-2">
                            <div className="bg-light-card d-flex align-items-center Property-View-lable">
                              <div className="w-50 Property-View-lable">
                                {item.name}
                              </div>
                              <div
                                className={
                                  "w-50 Property-View-lable-left word-wrap"
                                }
                              >
                                {item.key === "totalFloors"
                                  ? totalFloor
                                    ? totalFloor
                                    : "-"
                                  : item.key === "leaseEscalationValue"
                                  ? leaseEscalationValue
                                    ? leaseEscalationValue
                                    : "-"
                                  : item.key === "rentFreePeriod"
                                  ? rentFreePeriod
                                    ? rentFreePeriod
                                    : "-"
                                  : item.key === "lockInPeriod"
                                  ? item.key in apiData
                                    ? apiData[item.key] === 1
                                      ? apiData[item.key] + " year"
                                      : apiData[item.key] + " years"
                                    : "-"
                                  : "additionalDetails" in apiData
                                  ? item.key in apiData?.additionalDetails
                                    ? item.key === "propertyAge"
                                      ? ageOfProperty
                                        ? ageOfProperty
                                        : "-"
                                      : item.key === "cabins"
                                      ? checkForZero(
                                          apiData?.additionalDetails[item.key]
                                        )
                                      : item.key === "meetingRooms"
                                      ? checkForZero(
                                          apiData.additionalDetails[item.key]
                                        )
                                      : apiData.additionalDetails[item.key] +
                                        [feet ? " ft" : ""]
                                    : item.key
                                    ? apiData[item.key]
                                    : "-"
                                  : "-"}
                              </div>
                            </div>
                          </Col>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              : ""}
          </>
        ) : props.typeData === "Property Documents" ? (
          <>
            {docCati?.map(
              (itemData) =>
                apiData?.documents?.[itemData]?.length > 0 &&
                apiData?.documents?.[itemData]?.map((item, i) => (
                  <Col sm={props.sm} md={props.md} className="mt-2" key={i}>
                    <div className="bg-light-card d-flex align-items-center Property-View-lable">
                      <div>
                        <img src={props.image} alt="docimage" />
                      </div>
                      <div className="w-50 Property-View-lable">{item}</div>
                      <div
                        className={`w-50 Property-View-lable-left ${
                          props.pdf ? "pdf-viewer" : null
                        }`}
                      >
                        {props.icons}
                      </div>
                    </div>
                  </Col>
                ))
            )}
          </>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
}
